/* Header Styles */

.account-header-container {
  display: flex;
  flex-direction: row;
  height: 60px;
  width: 98%;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  z-index: 10;
  background-color: #455560;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.account-header-text {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  width: 120px;
}

.account-header-button {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  background-color: #fff;
  width: 120px;
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  border: none;
  cursor: pointer;
}

.account-item-container {
  display: flex;
  flex-direction: row;
  min-height: 60px;
  width: 98%;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  z-index: 10;
  border-bottom: solid #f2f2f2 1px;
}

.account-item-text {
  color: #1d1d1d;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: left;
  width: 120px;
}
.account-category-selector-container {
  width: 280px;
  padding-right: 30px;
}

.account-item-unreconciled {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #1d1d1d;
}

.lds-dual-ring {
  display: inline-block;
  width: 12px;
  height: 12px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 10px;
  height: 10px;
  /* margin: 8px; */
  border-radius: 50%;
  border: 2px solid #1d1d1d;
  border-color: #1d1d1d transparent #1d1d1d transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
