.budget-header-container {
  display: flex;
  flex-direction: row;
  height: 60px;
  width: 98%;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #455560;
  z-index: 10;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.budget-footer-container {
  display: flex;
  flex-direction: row;
  height: 60px;
  width: 98%;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #455560;
  z-index: 10;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.budget-header-text {
  color: #fafafa;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  width: 130px;
}

.budget-header-button {
  color: #1d1d1d;
  font-size: 14px;
  font-weight: bold;
  background-color: #fafafa;
  width: 180px;
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  border: none;
  cursor: pointer;
}
