.profile-list-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  align-items: center;
  padding: 10px;
  border-bottom: #d2d2d2 1px solid;
}

.profile-list-info-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  align-items: center;
  padding: 10px;
  border-bottom: #d2d2d2 1px solid;
}

.profile-list-item-text {
  color: #1d1d1d;
  font-family: "Avenir Book";
  font-size: 16px;
  font-weight: bold;
  cursor: default;
}

.profile-list-item:hover {
  background-color: #d2d2d2;
}
