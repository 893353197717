.side-menu-container {
  width: 260px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  position: fixed;
}

.disabled-menu-item-container {
  border: 0px;
  height: 45px;
  width: 90%;
  background-color: #d2eff6;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.menu-item-subheading-container {
  padding-top: 8px;
  display: flex;
  width: 90%;
}

.menu-item-subheading {
  color: #416b75;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}
