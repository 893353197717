.amount-input {
  height: 25px;
  width: 80px;
  margin-left: -20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: bold;
  color: #647275;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  padding-left: 25px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.amount-input-adornment {
  z-index: 9;
  font-weight: bold;
}

/* Remove Arrows */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}