.login-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #455560;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .login-paper {
    width: 90%;
}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .login-paper {
        width: 500px;
        padding: 50px 0px 50px 0px;
  }
}
