.parent-category-container {
  display: flex;
  flex-direction: row;
  height: 60px;
  align-items: center;
  border-bottom: #fff 2px solid;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(69, 85, 96, 0.5);
  width: 98%;
}

.parent-category-container:hover {
  cursor: pointer;
  color: #ffffff;
}

.parent-category-text {
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  width: auto;
}

.child-category-text {
  color: rgb(69, 85, 96);
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  width: 130px;
}

.parent-category-button {
  height: 45px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-left: 10px;
  border-radius: 5px;
}

.parent-category-button:hover {
  background-color: rgb(69, 85, 96);
  color: #fff;
}

.currency::before {
  content: "R ";
}

.child-category-container {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  /* border-radius: 5px; */
}

.child-category-item {
  display: flex;
  flex-direction: row;
  height: 60px;
  width: 100%;
  align-items: center;
  border-bottom: #666 1px solid;
  margin-right: 20px;
}

.child-category-item:hover {
  background-color: rgba(69, 85, 96, 0.2);
}

.child-item-save-button {
  text-align: center;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 5px;
}

.child-category-item-button {
  width: 80px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.negative-amount {
  color: #b22222;
  font-weight: bold;
}

.edit-hover {
  cursor: pointer;
}

.parent-category-input {
  height: 25px;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: bold;
  color: #647275;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: left;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding-left: 8px;
}
