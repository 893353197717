.category-selector-button {
  width: 280px;
  text-align: left;
  background-color: transparent;
  padding: 3px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  color: #416b75;
  font-size: 0.875rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.category-selector-button:hover {
  background-color: #f8f9fa;
}

.category-selector-button-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
}

.category-loading-text {
  text-align: left;
  color: #416b75;
  font-size: 0.875rem;
  font-weight: bold;
}

.category-option-text {
  text-align: left;
  color: #416b75;
  font-size: 0.875rem;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.category-option-selector-paper {
  width: 300px;
  padding: 5px;
  background-color: #f8f9fa;
  box-shadow: 3px 5px 34px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 3px 5px 34px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 5px 34px -1px rgba(0, 0, 0, 0.75);
  border-radius: 4px;
}

.category-option-item {
  height: 40px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.category-option-item:hover {
  background-color: #d2d2d2;
}

.selected-category-item {
  margin-top: 4px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #1d1d1d;
}
