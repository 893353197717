/* Navbar Styles */

.navbar-container {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #455560;
  z-index: 99;
  height: 70px;
}

.navbar-internals {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 70px;
}

.navbar-logo {
  width: 160px;
  height: auto;
  padding-left: 10px;
  background-color: #455560;
}

.menu-toggle-button {
  margin-left: 20px;
  border: 0;
  background-color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
}

.menu-toggle-button:hover {
  background-color: #EEB111;
  cursor: pointer;
  color: #ffffff;
}

.date-switcher-container {
  width: 200px;
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.budget-amount-container {
  width: 200px;
  height: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.2);
  margin-left: 30px;
  background-color: #fafafa;
}

.amount-descriptor-text {
  color: #1d1d1d;
  text-align: center;
  font-size: 12px;
  margin-bottom: 3px;
}

.amount-value-text {
  color: #1d1d1d;
  text-align: center;
  font-weight: bold;
}

.profile-button {
  margin-right: 20px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: none;
  border: none;
  cursor: pointer;
}
