.account-list-container {
  width: 250px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}

.account-menu-item-container {
  border: 0px;
  height: 45px;
  width: 90%;
  background-color: rgba(69, 85, 96, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.account-menu-item-container:hover {
  background-color: rgba(69, 85, 96);
}

.account-name {
  color: #ffffff;
  text-align: left;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}

.account-balance {
  text-align: right;
  font-weight: bold;
  color: #ffffff;
}

.account-balance::before {
  content: "R ";
}

/* 416b75 */
