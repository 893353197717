.account-selector-container {
  border: solid 1px #647275;
  height: 60px;
  width: 300px;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
}

.account-selector-text {
  margin-bottom: 5px;
  margin-top: 0px;
  width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.checkbox-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.checkbox-unselected {
  height: 20px;
  width: 20px;
  border: solid #647275 2px;
  border-radius: 5px;
  margin-right: 10px;
}

.checkbox-outer {
  height: 20px;
  width: 20px;
  border: solid #647275 2px;
  border-radius: 5px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-selected-inner {
  height: 16px;
  width: 16px;
  background-color: #647275;
  border: solid #fff 1px;
  border-radius: 4px;
}
