/* Reference */

.colors {
  color: #647275;
  color: #88e0f7;
  color: #d2eff6;
  color: #455560;
  color: #a5bcc2;
  color: #b22222;
}

/*  */
/* Text Styles */
/*  */

h2 {
  color: #1D1D1D;
}

h3 {
  color: #1D1D1D;
}

p {
  font-size: 14px;
  color: #1D1D1D;
}

.subheading {
  font-size: 16px;
  color: #1D1D1D;
}

.error-text {
  color: #b22222;
  text-align: left;
  font-size: small;
}
/*  */

/*  */
/* Button Styles */
/*  */

.dialog-button {
  width: 120px;
  height: 40px;
  margin: 10px;
  border-radius: 8px;
  background-color: #a5bcc2;
  box-shadow: none;
  border: 0;
  border-width: 2px;
  border-color: white;
  border-style: solid;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
}

.full-action-button {
  width: 90%;
  height: 40px;
  margin: 10px;
  border-radius: 8px;
  background-color: #455560;
  box-shadow: none;
  border: 0;
  border-width: 2px;
  border-color: white;
  border-style: solid;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
}

.action-button {
  width: 180px;
  height: 40px;
  border-radius: 8px;
  background-color: #455560;
  box-shadow: none;
  border: 0;
  border-width: 2px;
  border-color: white;
  border-style: solid;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
}

.outline-action-button {
  width: 180px;
  height: 40px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #455560;
  box-shadow: none;
  border: 0;
  border-width: 2px;
  border-color: #455560;
  border-style: solid;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.hyperlink-text {
  color: #1D1D1D;
  font-family: "Avenir Book";
  font-size: small;
  cursor: pointer;
  text-decoration: underline;
}

.large-hyperlink-text {
  color: #1d1d1d;
  font-family: "Avenir Book";
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.large-hyperlink-text:hover {
  text-decoration: underline;
}

/* General Styles */

.divider {
  width: 90%;
  height: 0.01rem;
  background-color: #a5bcc2;
}

.standard-page-container {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.page-container {
  min-width: 100vw;
  min-height: 100vh;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
}


.MuiFormLabel-root.Mui-focused {
  color: #455560 !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #455560 !important;
}