.menu-item-container {
  border: 0px;
  height: 45px;
  width: 90%;
  background-color: rgba(69, 85, 96, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.menu-item-icon {
  color: #ffffff;
  margin-left: 8px;
}

.menu-item-text {
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  margin-left: 8px;
}

.menu-item-container:hover {
  background-color: #455560;
}

.menu-item-container:disabled {
  background-color: #666666;
}

