.parent-category-item {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #455560;
  border-bottom: 1px solid #ced4da;
  border-radius: 4px;
}

.category-selector-outer-container {
  box-shadow: 3px 5px 34px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 3px 5px 34px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 5px 34px -1px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
}

.category-option-selector-paper-outgoing {
  height: 400px;
  width: 300px;
  padding: 5px;
  background-color: #f8f9fa;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow-y: scroll;
}

.category-search-container {
  height: 75px;
  background-color: #f8f9fa;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
}
